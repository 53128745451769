import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"


const Sketchbook = () => (
  <Layout>
    <SEOComponent  title="Sketchbook" />
    <div className="columns m-0 pt-4">
      <div className="column is-half is-offset-one-quarter">
        <StaticImage src="../images/thanos-sketch.jpg" alt="warmup sketch of Thanos' head and shoulders" />
      </div>
    </div>
  </Layout>
)

export default Sketchbook
